.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 90%; /* Increased from 80% to 90% */
  max-width: 800px; /* Example of a max-width to prevent the pop-up from becoming too wide on large screens */
  height: 90%;
}


h2 {
  margin-top: 0;
}

form {
  margin-top: 20px;
}

label {
  display: block;
  margin-bottom: 10px;
}

.card-container {
  width: 100%; /* Ensure the container takes the full width of its parent */
  max-width: 250px; /* Optional: limit the max-width as needed */
  margin: 0 auto; /* Center the container if needed, but it will not affect the alignment of the CardElement */
}

.CardElement {
  width: 200%; /* Ensure the CardElement takes the full width of its parent */
  border: 2px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px; /* Adjust spacing as needed */
}


button[type="submit"] {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}

button[type="submit"]:disabled {
  background-color: #dcdcdc;
  color: #888;
  cursor: not-allowed;
}

.cancel-btn {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cancel-btn:hover {
  background-color: #c82333;
}
