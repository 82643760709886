/* ProductsHome.css */

.product-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.product-item {
  width: 300px;
  margin: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.product-item img {
  width: 100%;
  height: auto;
  margin-bottom: 15px;
  border-radius: 5px;
}

.product-details {
  margin-bottom: 15px;
}

.product-details h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.product-details p {
  font-size: 14px;
  color: #777;
}

.product-price {
  font-size: 18px;
  font-weight: bold;
  color: #007bff;
}

.add-to-cart-button {
  display: block;
  width: 100%;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-to-cart-button:hover {
  background-color: #0056b3;
}
