.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; 
    overflow-y: auto;
}

.popup-content {
    width: 80%;
    max-height: 90vh; 
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    overflow-y: auto; /* ensures scrollbar is present when needed */
    position: relative; /* ensures that the content respects the boundaries */
}

.popup-content h2 {
    text-align: center;
    margin-bottom: 20px;
}

.popup-content label {
    display: block;
    margin-bottom: 10px;
}

.popup-content input,
.popup-content select {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
}

.popup-content button {
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.popup-content button:hover {
    background-color: #0056b3;
}
