
.loading-spinner {
  border: 10px solid rgba(0, 0, 0, 0.1);
  border-left-color: #3498db;
  border-radius: 70%;
  width: 120px;
  height: 120px;
  animation: spin 1s linear infinite;
  margin: auto;
  margin-top: 30px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
