.cart-container {
  width: 70%;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
}

.cart-heading {
  font-size: 24px;
  margin-bottom: 20px;
}

.cart-items {
  list-style-type: none;
  padding: 0;
}

.cart-item {
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
}

.item-details {
  flex-grow: 1;
}

.item-name {
  font-weight: bold;
}

.item-quantity {
  text-align: right;
}


.checkout-btn {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
}

.checkout-btn:hover {
  background-color: #0056b3;
  width: 'auto';
}
