.loader {
  width: fit-content;
  font-weight: bold;
  font-family: sans-serif;
  font-size: 30px;
  padding: 0 5px 8px 0;
  background: repeating-linear-gradient(90deg,currentColor 0 8%,#0000 0 10%) 200% 100%/200% 3px no-repeat;
  animation: l3 2s steps(6) infinite;
}
.loader:before {
  content:"Loading..."
}
@keyframes l3 {to{background-position: 80% 100%}}